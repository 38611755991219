module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.4.0_gatsby@5.4.2_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91527f458da38ac3ad4b1864f482d6e2"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.10.5_gatsby@5.4.2/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-indicator@1.0.1/node_modules/gatsby-plugin-scroll-indicator/gatsby-browser.js'),
      options: {"plugins":[],"color":"#279A8C","height":"3px","paths":["/"],"zIndex":"9999"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-scroll-reveal@0.0.7_gatsby@5.4.2_react@18.2.0/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.4.2_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
